<template>
  <div class="">
    <Navbar/>
    <Menu/>
  </div>


  <div class="container uk-padding" :class="{'container_active': ($store.state.winW > 960 && $store.state.activeNav), 'item_video': $route.name === 'video_item'}" id="container__main">
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/layouts/Navbar'
import Menu from '@/layouts/Menu'

  export default {
    components: {
      Navbar, Menu
    },

    created() {
      if (!Object.keys(this.$store.state.user).length) {

        this.$watch('$store.state.user', () => {
          this._checkAccessPanel()
        })

        this.$store.dispatch('getUser')
      } else {
        this._checkAccessPanel()
      }

      document.body.setAttribute('uk-img', '');
      document.body.setAttribute("data-src", '/images/bg.jpg');
    },

    methods: {
      _checkAccessPanel() {
        if (this.$store.state.user.role != 0) {
          this.$router.push({path: '/admin'})
        }
      }
    }
  }
</script>
