<template>
  <div
    id="menu" class="uk-width-medium uk-height-1-1 uk-position-fixed uk-box-shadow-medium z-index-5"
    :class="{'menu__active': $store.state.activeNav}"
    ref="nav"
    >
    <div class="uk-grid uk-grid-small uk-padding-small uk-flex-middle">
    <!--  <div>
        <a><img class="uk-border-circle" src="https://cdn.vevanta.com/stages/1194/10728006198e6cd05740a1fb519da6a8.jpg?w=48&h=48" alt=""></a>
      </div> -->
      <div class="fnt-bld">{{ fioUser }}</div>
    </div>
    <div class="uk-margin-top">
      <ul class="uk-list uk-text-left menu__items uk-width-1-1" uk-nav>
        <router-link
          to="/"
          custom
          v-slot="{isActive, navigate}"
        >
          <li class="uk-padding-small cr-pointer" :class="{active: isActive}" @click="navigate">Видеозаписи</li>
        </router-link>

    <!--    <router-link
          to="/users"
          custom
          v-slot="{isActive, navigate}"
        >
          <li class="uk-padding-small cr-pointer" :class="{active: isActive}" @click="navigate">Тело Христа</li>
        </router-link> -->
      <!--  <router-link
          to="/newspapper"
          custom
          v-slot="{isActive, navigate}"
        >
          <li class="uk-padding-small cr-pointer" :class="{active: isActive}" @click="navigate">Газета</li>
        </router-link> -->

      <!--  <router-link
          to="/churchs"
          custom
          v-slot="{isActive, navigate}"
        >
          <li class="uk-padding-small cr-pointer" :class="{active: isActive}" @click="navigate">Наши друзья</li>
        </router-link> -->

        <li class="uk-padding-small cr-pointer"  @click="$store.dispatch('LOGOUT')">Выход</li>
      </ul>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
export default {
  data() {
    return {
      nav: false,
      touchNavX: 0,
    }
  },

  created() {

    if (window.innerWidth <= 960) {
      this.$store.dispatch('hideNav')
    }
    
    window.addEventListener('resize', () => {
      this.$store.commit('setWinW')
    })
  },
  mounted() {
    this.$refs.nav.addEventListener('touchstart', e => {
      this.touchNavX = e.touches[0].clientX
    })

    this.$refs.nav.addEventListener('touchend', e => {
      if (e.changedTouches[0].pageX <= (this.touchNavX - 30)) {
          this.$store.dispatch('toggleNav')
      }

    })
  },

  computed: {
    fioUser() {
      if (Object.keys(this.$store.state.user).length) {
        let user = this.$store.state.user
        return `${user.first_name}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

  .p8 {
    padding: 8px
  }

  ul.uk-nav-sub {
    padding: 0
  }

  .nav__circle {
    width: 10px;
    height: 10px;
    border: 3px solid #5cc6d2
  }

  .uk-nav a {
    color: #6d6d6d
  }

  #menu {
    top: 0;
    z-index: 2;
    padding-left: 20px;
    background: #fff;
    transition: 0.2s;
    transform: translateX(-100%)
  }

  .menu__active {
    transform: translateX(0%) !important
  }

  .menu__items > li {
    margin: 0 !important;
    position: relative;
    color: #6d6d6d;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px
  }

  .menu__items > li.active {
    background: #f5f5f5;
    color: hsl(185deg 64% 51%);
  }

  .menu__items li.active::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 5px;
    border-radius: 12px;
    background: hsl(185deg 64% 51%);
  }

  .menu__items
  .menu__items > li:hover::before {
    background: #00bab6
  }

  .menu__items > li:hover {
    background: #f5f5f5;
    transition: 0.2s
  }



</style>
